import React from 'react';
import {useHeaderData} from 'shared/Hooks/Headers';
import Case from '../../sections/Case';
import {useCaseData} from '../../shared/Hooks/Case';

const CaseVox = () => {
  const data = {
    seo: {
      title: 'VOX – Modułowy system ekspozycyjny',
    },
    cases: useCaseData().vox,
    header: useHeaderData().vox,
  };

  return (
    <Case data={data}/>
  );
};

export default CaseVox;
